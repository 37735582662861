"use strict";

const JSZip = require("jszip");

// Converts data from a ReadableStream object
// to a Buffer
const streamToBuffer = async (stream) => {
	let buffers = [];
	let reader = stream.getReader();
	while (true)
	{
		const { done, value } = await reader.read();
		if (done) {
			break;
		}
		buffers.push(Buffer.from(value));
	}
	let buffer = Buffer.concat(buffers);
	return buffer;
};

const readFileToBuffer = async function(file)
{
	return new Promise((resolve, reject) => {
		var reader = new FileReader();
		reader.onerror = function() {
			reject(reader.error);
		};
		reader.onload = function(e) {
			resolve(reader.result);
		}
		reader.readAsArrayBuffer(file);
	});
};

// Loads a zip archive from File submitted by user.
// Returns an object representing the zip archive.
const loadZipArchive = async function(file)
{
	let zip = new JSZip();
    let zipData = await zip.loadAsync(file);
	return zipData;
};

// Adds a file inside a zip file
// zipArchive: jszip object representing the zip archive
// fileName: Name of the file to be added inside the zip file
// fileContent: The content of the file to be added
// Returns the content of the zip file as an arraybuffer
const addFileToZip = async function(zipArchive, filename, fileContent)
{
    // Add the file inside the zip file
    zipArchive.file(filename, fileContent);

    // Recreate the zip file
    const modifiedZipData = await zipArchive.generateAsync({
		type: 'arraybuffer',
		compression: "DEFLATE"
	});

	return modifiedZipData;
};

// Zip files
// files : List of files selected by user
// extraFileName : Name of an additional file to be added to the archive
// extraFileContent : Content of the additional file to be added to the archive
const zipFiles = async function (files, extraFileName, extraFileContent)
{
	const zip = new JSZip();
	var n = files.length;
	for (var i = 0; i < n; i++)
	{
		let buffer = readFileToBuffer(files[i]);
		zip.file(files[i].name, buffer);
	}

	if (extraFileName != null)
	{
		// Add an extra file
		zip.file(extraFileName, extraFileContent);
	}

	/*
	possible values for type in generateAsync
	base64
	binarystring
	array
	uint8array
	arraybuffer
	blob
	nodebuffer
	*/
	let content = await zip.generateAsync({
		type: 'uint8array',
		compression: "DEFLATE"
	});
	
	//This could be done to let the user download the zip file
	//FileSaver.saveAs(content, 'download.zip');
	// Need to include this at the top
	// const FileSaver = require("file-saver");

	return content;
};

// Gets filenames inside zip file
// zipFileContent: Then zip file bytes
const getFilenamesInZip = async function(zipFileContent)
{
	return new Promise((resolve, reject) => {
		let filenames = [];
		try
		{
			JSZip.loadAsync(zipFileContent).then(function(zip) {
				for (let [filename, file] of Object.entries(zip.files)) {
					filenames.push(filename);
				}
				resolve(filenames);
			}).catch(function(err) {
				reject(err);
			});
		}
		catch(err)
		{
			reject(err);
		}
	});
};

// Get file in zip given the ending of the filename. E.g. the extension of the file
// or just some string that the filename ends with.
// zipFileContent: bytes of the zip file
// fileEnding: String that the filename ends with
// Returns the first file whose name ends with fileEnding
// If no file is found then null is returned
const getFileInZipByEnding = async function(zipFileContent, fileEnding)
{
	return new Promise((resolve, reject) => {
		let fileFound = null;
		try
		{
			JSZip.loadAsync(zipFileContent).then(function(zip) {
				for (let [filename, file] of Object.entries(zip.files)) {
					if (filename.endsWith(fileEnding))
					{
						fileFound = file;
						break;
					}
				}
				resolve(fileFound);
			}).catch(function(err) {
				reject(err);
			});
		}
		catch(err)
		{
			reject(err);
		}
	});
};

// Infer the icon string to use for cloud entry
// given the contents of a .arkioimport file
// as a string
const inferIconFromArkioImport = function(arkioImportStr)
{
	let icon = null;
	let arkioImport = null;
	try {
		arkioImport = JSON.parse(arkioImportStr);
	} catch (e) {
		return null;
	}
	if (arkioImport.Product != null)
	{
		switch (arkioImport.Product)
		{
			case "Revit":
				icon = Constants.Icon.Revit;
				break;
			case "Rhino":
				icon = Constants.Icon.Rhino;
				break;
			case "SketchUp":
				icon = Constants.Icon.SketchUp;
				break;
			default:
				break;
		}
	}
	return icon;
};

// Check zip file content
// and return icon string based on contents of .arkioimport
// file if it is found inside the zip
// zipFileContent: bytes of a zip file
const inferIconFromZip = async function(zipFileContent)
{
	// try finding a file in the zip that ends with ".arkioimport"
	let file = await getFileInZipByEnding(zipFileContent, ".arkioimport");
	if (file != null)
	{
		let text = await file.async('string');
		let icon = inferIconFromArkioImport(text);
		return icon;
	}
	return null;
};

const inferIconFromFiles = function(files)
{
	let n = files.length;
	for(let i = 0; i < n; i++)
	{
		if(files[i].name.endsWith(".arkioimport"))
		{
			let buffer = readFileToBuffer(files[i]);
			let uint8arr = new Uint8Array(buffer);
			let str = new TextDecoder().decode(uint8arr);
			let icon = inferIconFromArkioImport(str);
			return icon;
		}
	}
	return null;
};

module.exports = {
	streamToBuffer : streamToBuffer,
	readFileToBuffer : readFileToBuffer,
	zipFiles : zipFiles,
	getFilenamesInZip : getFilenamesInZip,
	inferIconFromZip : inferIconFromZip,
	inferIconFromFiles : inferIconFromFiles,
	addFileToZip : addFileToZip,
	loadZipArchive : loadZipArchive
};
