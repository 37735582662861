"use strict";

var axios = require("axios");

const Util = require("./Util.js");
const Cloud = require("./Cloud.js");

// For testing axios
const getTextFromUrl = async function(url)
{
	try
	{
		const response = await axios.get(url, {
			headers: {},
		});

		// Access the response data
		const data = response.data;
		// Process the response data
		console.log(data);

		return response;
	}
	catch (error)
	{
		console.error('Error in getTextFromUrl: ', error);
	}
	return null;
};

// Call this to create a data set on unity cloud storage
const postToStorage = async (dsName, filename, bucketObjectName) => {

	const data =
	{
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
	};

	let dataSetName = encodeURIComponent(dsName);
	let filnam = encodeURIComponent(filename);
	let bucketObjName = encodeURIComponent(bucketObjectName);

	const apiEndpoint = ""; // put url to storage endpoint here
	const apiUrl = apiEndpoint + "?dataSetName=" + dataSetName + "&filename=" + filnam + "&bucketObjectName=" + bucketObjName;
  
	try {
		const response = await axios.post(apiUrl, data);
		console.log('Post to Unity Cloud finish', response.data);
		return response.data;
	} catch (error) {
		console.error('Post to Unity Cloud error', error);
	}
};

const commitDataSet = async (dsId, dsVersionId) => {

	const data =
	{
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
	};

	let dataSetId = encodeURIComponent(dsId);
	let dataSetVersionId = encodeURIComponent(dsVersionId);
	
	const apiEndpoint = ""; // put url to commitdataset endpoint here
	const apiUrl = apiEndpoint + "?dataSetId=" + dataSetId + "&dataSetVersionId=" + dataSetVersionId;
  
	try {
		const response = await axios.post(apiUrl, data);
		console.log('Commit DataSet finish', response.data);
		return response.data;
	} catch (error) {
		console.error('Commit DataSet error', error);
	}
};

// Upload file and create dataset for datastreaming
// and commit the dataset
// fileContent : Uint8Array with the content of the file
// filename : The name of the file to post
// Returns object with a "success" field and some other data
const postAndCommit = async (fileContent, filename) => {

	let result = {};
	result.success = false;

	let hash = CryptoUtil.createHash(fileContent);
	let putRes = await Cloud.putObjectToUnityBucketRaw(hash, fileContent);

	if (!putRes)
	{
		console.error('Upload failed');
		return result;
	}

	console.log('Uploaded file to bucket');

	// upload the file to unity cloud storage
	let dataSetName = CryptoUtil.createGUID();
	console.log('dataSetName ' + dataSetName);
	let resp = await DataStreamingCloud.postToStorage(dataSetName, filename, hash);

	if (resp == null)
	{
		console.error('Failed to post to storage');
		return result;
	}

	console.log(resp);

	if (resp.statusCode !== 201)
	{
		console.error('Failed to commit file!');
		return result;
	}

	console.log('Post to storage finished successfully. Still need to commit');
	console.log('Commiting dataset');

	result.dataSetId = resp.dataSetId;
	result.dataSetVersionId = resp.dataSetVersionId;
	resp = await DataStreamingCloud.commitDataSet(resp.dataSetId, resp.dataSetVersionId);

	if (resp == null)
	{
		console.error("Failed to committ dataset!");
		return result;
	}

	console.log(resp);

	if (resp.statusCode !== 200)
	{
		console.error("Failed to committ dataset!");
		return result;
	}

	console.log('Dataset commit succeeded!');
	result.success = true;
	return result;
};

// uploads model to cloud for streaming and adds entry in index file
const addModelForStreaming = async (fileContent, filename, encryptionKey) => {
	let postRes = await postAndCommit(fileContent, filename);
	let result = {};
	result.success = false;
	if (!postRes.success)
	{
		return result;
	}

	// Create new entry in index file
	var entry = {};

	entry.Created = Util.getCurrentFormattedUTCDate();
	var tomorrow = new Date();
	tomorrow.setMilliseconds(tomorrow.getMilliseconds() + Constants.EntryLifespan);
	entry.Expires = Util.getFormattedUTCDate(tomorrow);

	// Stream field for the datastreaming type of entries
	entry.Stream = {};
	entry.Stream.Filename = filename;
	result.entry = entry;

	let dataSetId = postRes.dataSetId;
	var indexRes = await Cloud.addToIndex(dataSetId, entry, encryptionKey);

	if (indexRes.indexFile != null)
	{
		result.indexFile = indexRes.indexFile;
	}

	result.success = indexRes.success;

	return result;
};

module.exports = {
	getTextFromUrl : getTextFromUrl,
	postToStorage : postToStorage,
	commitDataSet : commitDataSet,
	postAndCommit : postAndCommit,
	addModelForStreaming : addModelForStreaming
};
