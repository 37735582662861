"use strict";

const Util = require("./Util.js");

// Representing an index file in the cloud
// It's a file containing a list of files that have 
// been uploaded to the cloud.
const IndexFile = function () {

	// Name of the cloud sharing group
	this.GroupName = "";

	// Entries in the index file
	this.Entries = {};

	// Deserialize the index file from an array of json bytes
	this.Deserialize = function (bytes)
	{
		// Get json string from bytes
		let bytesView = new Uint8Array(bytes);
		let json = new TextDecoder().decode(bytesView);

		var indexObj = JSON.parse(json);
		console.log(indexObj);

		this.GroupName = indexObj.GroupName;
		this.Entries = indexObj.Entries;
	};

	// Serialize the index file to json code bytes
	this.Serialize = function()
	{
		var indexfile = {};
		indexfile.GroupName = this.GroupName;
		indexfile.Entries = this.Entries;
		var json = JSON.stringify(indexfile);
		var jsonBytes = new TextEncoder().encode(json);
		return jsonBytes;
	};

	// For checking if an entry is expired
	this.IsEntryExpired = function(entry)
	{
		const currentDT = new Date();
		const entryExpires = entry.Expires;
		const entryExpiresDT = Util.cloudEntryDateStringToDate(entryExpires);
		if (currentDT > entryExpiresDT)
		{
			return true;
		}
		return false;
	};

	// Removes an entry from the index file
	this.RemoveEntry = function(entryHash)
	{
		delete this.Entries[entryHash];
	};

	// Removes expired entries from the index file
	this.RemoveExpiredEntries = function()
	{
		for(const key in this.Entries)
		{
			let entry = this.Entries[key];
			let expired = this.IsEntryExpired(entry);
			if (expired)
			{
				delete this.Entries[key];
			}
		}
	};

	// Get the entries as an array
	this.GetEntriesAsArray = function()
	{
		let entryArr = [];
		for(const key in this.Entries)
		{
			entryArr.push({entryHash : key, entryValue : this.Entries[key]});
		}
		return entryArr;
	};
};

module.exports = IndexFile;
