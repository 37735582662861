"use strict";

// Get parameter value from url
const getUrlPar = function(url, parName)
{
	if (url === '' || url === null) return null;
    const urlObj = new URL(url);
    return urlObj.searchParams.get(parName);
};

// Removes a parameters along with its value from a query string
const removeUrlPar = function(querystring, parName)
{
	let newQueryString = '';
	if (querystring[0]==='?') {
		newQueryString += '?';
		querystring = querystring.substring(1, querystring.length);
	}
	let parts = querystring.split('&');
	let i = 0;
	let n = parts.length;
	let newParts = [];
	for (i = 0; i < n; i++) {
		let part = parts[i];
		if (!part.startsWith(parName + '=')) {
			newParts.push(part);
		}
	}

	for (i = 0; i < newParts.length; i++) {
		newQueryString += (i !== 0 ? "&" : "") + newParts[i];
	}

	if (newQueryString === "?") newQueryString = "";

	return newQueryString;
};

const prep0IfMissing = function(str, n)
{
    let output = str + "";
    const strlen = output.length;
    if (strlen < n)
    {
        const m = n - strlen;
        for (var i = 0; i < m; i++)
        {
            output = "0" + output;
        }
        return output;
    }
    return str;
};

// Converts a date string from cloud index file
// to a date object
// Example of such a string:
// "06/27/2022 14:30:55"
const cloudEntryDateStringToDate = function(datestr)
{
	let datemod = datestr.trim();
	let dt = datemod.split(' ');
	let d = dt[0];
	let t = dt[1];
	let dateParts = d.split('/');
	let timeParts = t.split(':');
	let month = dateParts[0];
	let day = dateParts[1];
	let year = dateParts[2];
	let hour = timeParts[0];
	let minutes = timeParts[1];
	let seconds = timeParts[2];
	// Create a date string that will be interpreted as an UTC date
	// The Z at the end of the string makes in interpreted as UTC
	let utcDateStr = year + '-' + month + '-' + day + 'T' +
	hour + ':' + minutes + ':' + seconds + 'Z';
	let utcDate = new Date(utcDateStr);
	return utcDate;
};

// Convert a date object to a formatted date string.
// Example of output
// "06/27/2022 14:30:55"
const getFormattedUTCDate = function(date)
{
	const utcdatestr = 
	prep0IfMissing(date.getUTCMonth() + 1, 2) +
	'/' + prep0IfMissing(date.getUTCDate(), 2) +
	'/' + date.getUTCFullYear() +
	' ' + prep0IfMissing(date.getUTCHours(), 2) +
	':' + prep0IfMissing(date.getUTCMinutes(), 2) +
	':' + prep0IfMissing(date.getUTCSeconds(), 2);
	return utcdatestr;
};

// Convert a date object to a formatted date and time string for displaying in the UI
// Example of output
// "2022/06/27 14:30:55"
const getFormattedUTCDateTimeForUI = function(date)
{
	return getFormattedUTCDateForUI(date) + ' ' + prep0IfMissing(date.getUTCHours(), 2) +
	':' + prep0IfMissing(date.getUTCMinutes(), 2) + ':' + prep0IfMissing(date.getUTCSeconds(), 2);
};

// Convert a date object to a formatted date string for displaying in the UI
// Example of output
// "2022/06/27"
const getFormattedUTCDateForUI = function(date)
{
	return date.getUTCFullYear() + '/' + prep0IfMissing(date.getUTCMonth() + 1, 2) + '/' + prep0IfMissing(date.getUTCDate(), 2);
};

// Get current formatted UTC date time
const getCurrentFormattedUTCDate = function()
{
	const date = new Date();
	const dateStr = getFormattedUTCDate(date);
	return dateStr;
};

// Get current formatted UTC date time for displaying in the UI
const getCurrentFormattedUTCDateTimeForUI = function()
{
	return getFormattedUTCDateTimeForUI(new Date());
};

// Get current formatted UTC date for displaying in the UI
const getCurrentFormattedUTCDateForUI = function()
{
	return getFormattedUTCDateForUI(new Date());
};

const getFilenameWithoutExtension = function(filename)
{
	let i = filename.lastIndexOf(".");
	if (i >= 0)
	{
		let result = filename.substring(0, i);
		return result;
	}
	return filename;
};

// Get filename from a full file path
const getFilenameFromPath = function(path)
{
	let parts = path.split(/[\\\/]/);
	let filename = parts[parts.length - 1];
	return filename;
}

// call with with await inside async method to let
// executing method sleep for so many milliseconds
const sleep = async function(millisec)
{
	return new Promise((resolve) => {
		setTimeout(resolve, millisec);
	});
};

// Adds or updates a GET parameter in a URL
const addOrUpdateUrlParameter = function(url, paramName, paramValue) {
    try {
        // Create a new URL object
        const urlObj = new URL(url);
        // Get the URLSearchParams object from the URL
        const params = urlObj.searchParams;

        params.set(paramName, paramValue);
        return urlObj.toString();
    } catch (error) {
        // Handle invalid URLs
        console.error('Invalid URL:', error);
        return url;
    }
};

module.exports = {
	prep0IfMissing : prep0IfMissing,
	getFormattedUTCDate : getFormattedUTCDate,
	cloudEntryDateStringToDate : cloudEntryDateStringToDate,
	getCurrentFormattedUTCDate : getCurrentFormattedUTCDate,
	getFilenameWithoutExtension : getFilenameWithoutExtension,
	getFilenameFromPath : getFilenameFromPath,
	sleep : sleep, 
	getUrlPar : getUrlPar,
	removeUrlPar : removeUrlPar,
	addOrUpdateUrlParameter : addOrUpdateUrlParameter,
	getCurrentFormattedUTCDateTimeForUI : getCurrentFormattedUTCDateTimeForUI,
	getCurrentFormattedUTCDateForUI : getCurrentFormattedUTCDateForUI
};
