"use strict";

var CryptoJS = require("crypto-js");
const shajs = require("sha.js");
const aesjs = require("aes-js");
const pkcs7 = require('pkcs7-padding');
const Util = require("./Util.js");

// Creates a random encryption key made up of
// 32 bytes
// Returns Uint8Array object
const createRandomEncryptionKey = function()
{
	var bytes = [];
	for(var i = 0; i < 32; i++)
	{
		var b = Math.floor(Math.random() * 256);
		bytes.push(b);
	}
	const arr = new Uint8Array(bytes);
	return arr;
};

// Create random string of length n with the specified letters
// letters : string containing the allowed letters in the generated string
// n : the length of the generated string
const createRandomString = function(letters, n)
{
	let lettersLen = letters.length;
	let code = "";
	for (let i = 0; i < n; i++)
	{
		let randIndex = Math.floor(Math.random() * lettersLen);
		code += letters[randIndex];
	}
	return code;
};

// Creates a random GUID
const createGUID = function()
{
	let letters = "0123456789abcdef";
	let code = createRandomString(letters, 8) +
	"-" + createRandomString(letters, 4) + 
	"-" + createRandomString(letters, 4) + 
	"-" + createRandomString(letters, 4) + 
	"-" + createRandomString(letters, 12);
	return code;
};

// Create random small invite code
const createRandomInviteCode = function()
{
	// numbers 0-9 and english uppercase alphabet
	// without the letter O, because it looks similar to 0
	let letters = "0123456789ABCDEFGHIJKLMNPQRSTUVWXYZ";
	let n = 6;
	let code = createRandomString(letters, n);
	return code;
};

// Convert an array of bytes to an array of words.
// Each word represents 4 bytes
const bytesToWords = function(bytes)
{
	return CryptoJS.lib.WordArray.create(bytes.buffer, bytes.length);
};

const wordsToBytes = function(wordArray)
{
	var wordCount = wordArray.words.length;
	var byteCount = wordArray.sigBytes;
	const u8arr = new Uint8Array(byteCount);

	var collectedBytes = 0;
	for (let i = 0; i < wordCount; i++)
	{
        var word = wordArray.words[i];
		if (collectedBytes == byteCount) break;
        u8arr[collectedBytes] = word >> 24;
		collectedBytes++;
		if (collectedBytes == byteCount) break;
        u8arr[collectedBytes] = (word >> 16) & 0xff;
		collectedBytes++;
		if (collectedBytes == byteCount) break;
        u8arr[collectedBytes] = (word >> 8) & 0xff;
		collectedBytes++;
		if (collectedBytes == byteCount) break;
        u8arr[collectedBytes] = word & 0xff;
		collectedBytes++;
	}
	return u8arr;
};

const bytesToBase64 = function(bytes)
{
	var words = bytesToWords(bytes);
	var base64 = CryptoJS.enc.Base64.stringify(words);
	return base64;
};

const base64ToBytes = function(base64)
{
	var words = CryptoJS.enc.Base64.parse(base64);
	var bytes = wordsToBytes(words);
	return bytes;
};

const byteToHexString = function(byte)
{
	var hex = byte.toString(16);
	hex = Util.prep0IfMissing(hex, 2);
	hex = hex.toUpperCase();
	return hex;
};

const byteArrayToHexString = function(byteArray)
{
	var hex = "";
	var n = byteArray.length;
	var i;
	for (i = 0; i < n; i++)
	{
		var hexByte = byteToHexString(byteArray[i]);
		hex = hex + hexByte;
	}
	return hex;
};

// Creates a hash for an array of bytes
const createHash = function(bytes)
{
	return shajs('sha1').update(bytes).digest('hex').toUpperCase();
};

// Creates a random hash that looks like a hash created with createHash
const createRandomHash = function()
{
	let bytes = [];
	for (let i = 0; i < 256; i++)
	{
		bytes.push(Math.floor(Math.random()*256));
	}
	let uint8arr = new Uint8Array(bytes);
	let hash = createHash(uint8arr);
	return hash;
};

// Create a 16 byte i vector word array from hash
const createIV = function(hash)
{
	var parsedHash = CryptoJS.enc.Base64.parse(hash);
	var words = [];
	for (var i = 0; i < 4; i++)
	{
		words.push(parsedHash.words[i]);
	}
	var ivWords = CryptoJS.lib.WordArray.create(words, 16);
	return ivWords;
};

// Deterministicly generate a 16 byte IV using a combination of a hash and a counter.
// Useful when encrypting streams, when a unique IV needs to be used
// when encrypting each block.
// Returns the IV as a Uint8Array
const createIVWithCounter = async function(subtleCrypto, hash, counter) {
	// Concatenate hash and counter and then hash the result
    const dataToHash = hash + counter;
    const hashBuffer = await subtleCrypto.digest('SHA-256', new TextEncoder().encode(dataToHash));
    return new Uint8Array(hashBuffer, 0, 16);
};

const nativeEncrypt = async function(subtleCrypto, encryptionKey, objectName, bytes) {
	let algorithm = "AES-CBC";
	let ivWords = createIV(objectName);
	let ivBytes = wordsToBytes(ivWords);
	const key_encoded = await subtleCrypto.importKey("raw", encryptionKey, algorithm, false, ["encrypt"]);
	const encrypted_content = await subtleCrypto.encrypt({ name: algorithm, iv: ivBytes}, key_encoded, bytes);
	return encrypted_content;
};

// This is a method for encryptiong a stream.
// In case we will be using streams for uploading sometime
// in the future it might be useful to keep this code around.
// subtleCrypto: window.crypto.subtle object to use for encrypting
// encryptionKey: The encryption key
// objectName: Name/hash of object
// readableStream: The stream to encrypt
// Returns a stream with encrypted data.
const nativeEncryptStream = async function (subtleCrypto, encryptionKey, objectName, readableStream) {
	const algorithm = 'AES-CBC';

	let counter = 0;

	// Chunk size
	// The encrypted chunk size will be this size plus 16
	// Because of the padded bytes
	// So when decrypting that has to be kept in mind
	// Might be simplest if this value is always a multiple of 16
	// TODO Automatically add/subtract 16 on this value
	// I think it might be better to have this number a lot larger
	// for better performance, but not too large, we still want
	// to be streaming and not storing large chunks in memory.
	// TODO find out what is a good number to use here
	const partSize = 64;

	let nextPart = new Uint8Array(partSize); // Next output chunk
	// Number of bytes in nextPart that have been written to
	let filledBytes = 0;
  
	try {
		const key_encoded = await subtleCrypto.importKey('raw', encryptionKey, algorithm, false, ['encrypt']);
		const encryptStream = new TransformStream({
			async transform(chunk, controller) {

				// Total length of chunk
				let chunkLength = chunk.length;
				// Index of next byte in chunk to be copied
				let nextChunkIndex = 0;

				// Continue consuming chunk while there are bytes left to be consumed
				while (nextChunkIndex < chunkLength)
				{
					// If there are bytes in nextPart remaining to be filled
					if (filledBytes < partSize)
					{
						let fillSize = partSize - filledBytes; // Number of bytes in nextPart to fill

						// Compute the end index in chunks to get data from
						let topChunkIndex = nextChunkIndex + fillSize;
						if (topChunkIndex > chunkLength)
						{
							topChunkIndex = chunkLength;
							
							// Adjust fillSize
							fillSize = topChunkIndex - nextChunkIndex;
						}

						// Copy from chunk to nextPart
						nextPart.set(chunk.subarray(nextChunkIndex, topChunkIndex), filledBytes);
						nextChunkIndex += fillSize;
						filledBytes += fillSize;
					}
					else
					{						
						let data = nextPart.slice();

						// Creating a unique iv
						// The counter ensures that it is unique
						let ivBytes = await createIVWithCounter(subtleCrypto, objectName, counter);
						counter++;

						let encryptedChunk = await subtleCrypto.encrypt({ name: algorithm, iv: ivBytes }, key_encoded, data);
						controller.enqueue(encryptedChunk);
						filledBytes = 0;
					}
				}
			},
			async flush(controller) {
				// This method is called after all chunks have been processed
				// Any finalization or cleanup logic can be done here

				// In case there are still some filled bytes
				if (filledBytes > 0)
				{
					let data = nextPart.subarray(0, filledBytes);

					let ivBytes = await createIVWithCounter(subtleCrypto, objectName, counter);
					counter++;

					let encryptedChunk = await subtleCrypto.encrypt({ name: algorithm, iv: ivBytes }, key_encoded, data);
					controller.enqueue(encryptedChunk);
				}
			}
		});

		// Piping the readable stream through the encryption stream
		const encryptedStream = readableStream.pipeThrough(encryptStream);

		return encryptedStream;
	} catch (error) {
		console.error('Error in nativeEncryptStream:', error);
		throw error; // rethrow the error for further handling if necessary
	}
};

const nativeEncryptStreamOld = async function (subtleCrypto, encryptionKey, objectName, readableStream) {
	const algorithm = 'AES-CBC';
	const ivWords = createIV(objectName);
	const ivBytes = wordsToBytes(ivWords);

	let counter = 0;
  
	try {
		const key_encoded = await subtleCrypto.importKey('raw', encryptionKey, algorithm, false, ['encrypt']);
		const encryptStream = new TransformStream({
			async transform(chunk, controller) {
				console.log('got chunk ' + counter);
				counter++;
				const encryptedChunk = await subtleCrypto.encrypt({ name: algorithm, iv: ivBytes }, key_encoded, chunk);
				controller.enqueue(encryptedChunk);
			}
		});

		// Piping the readable stream through the encryption stream
		const encryptedStream = readableStream.pipeThrough(encryptStream);

		return encryptedStream;
	} catch (error) {
		console.error('Error in nativeEncryptStream:', error);
		throw error; // rethrow the error for further handling if necessary
	}
};

const nativeDecrypt = async function(subtleCrypto, encryptionKey, objectName, encryptedBytes) {
	let algorithm = "AES-CBC";
    let ivWords = createIV(objectName);
    let ivBytes = wordsToBytes(ivWords);
	const keyEncoded = await subtleCrypto.importKey("raw", encryptionKey, algorithm, false, ["decrypt"]);
    const decryptedContent = await subtleCrypto.decrypt({ name: algorithm, iv: ivBytes}, keyEncoded, encryptedBytes);
    return decryptedContent;
};

function convert32BitIntArrayToByteArray(arr) {
	const out = [];
	for (const int32 of arr) {
		const byte1 = 0xff & int32;
		const byte2 = 0xff & (int32 >> 8);
		const byte3 = 0xff & (int32 >> 16);
		const byte4 = 0xff & (int32 >> 24);
		out.push(byte4, byte3, byte2, byte1);
	}
	return out;
}

// Encrypt bytes for an object using an encryption key and name of object
const encryptObjectData = function(encryptionKey, objectName, bytes)
{
	const iv = createIV(objectName);
	const ivBytes = convert32BitIntArrayToByteArray(iv.words);

	// Pad bytes using PKCS7 padding
	//
	// Because ArrayBuffers (and by extension Uint8Arrays) are fixed-size, this
	// creates a new Uint8Array (i.e. duplicates it in memory).
	bytes = pkcs7.pad(bytes);

	const aesCbc = new aesjs.ModeOfOperation.cbc(encryptionKey, ivBytes);
	const encryptedBytes = aesCbc.encrypt(bytes);
	return encryptedBytes;
};

// Decrypt bytes for an object using an encryption key and name of object
const decryptObjectData = function(encryptionKey, objectName, encryptedBytes)
{
	var ivWords = createIV(objectName);
	var encryptionKeyWords = bytesToWords(encryptionKey);
	var encryptedByteWords = bytesToWords(encryptedBytes);
	var cipherParams = CryptoJS.lib.CipherParams.create({
		ciphertext: encryptedByteWords
	});
	const cipherDecryption = CryptoJS.AES.decrypt(cipherParams, encryptionKeyWords,
	{
		  iv: ivWords,
		  padding: CryptoJS.pad.Pkcs7,
		  mode: CryptoJS.mode.CBC
	});
	var decryptedBytes = wordsToBytes(cipherDecryption);
	return decryptedBytes;
};

module.exports = {
	createRandomInviteCode : createRandomInviteCode,
	createRandomEncryptionKey : createRandomEncryptionKey,
	bytesToWords : bytesToWords,
	bytesToBase64 : bytesToBase64,
	base64ToBytes : base64ToBytes,
	createHash : createHash,
	createRandomHash : createRandomHash,
	encryptObjectData : encryptObjectData,
	decryptObjectData: decryptObjectData,
	createGUID : createGUID,
	nativeEncrypt : nativeEncrypt,
	nativeDecrypt : nativeDecrypt,
	nativeEncryptStream : nativeEncryptStream
};
